import React from 'react';
import { NavLink } from 'react-router-dom';
import "../css/navbar.css";
import Menu from '@mui/material/Menu';
import { Rotate as Hamburger } from 'hamburger-react'
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import amplitude from "amplitude-js"

function Navbar() {

  const [act1, setact1] = useState(false);
  const [act2, setact2] = useState(false);
  const [act3, setact3] = useState(false);
  const active = {
    background: '#C9E3FF',
    opacity: '0.9'
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const out = () => {
    localStorage.clear("Email");
    localStorage.clear("OTP");
    localStorage.clear("otps");
    window.location.reload();
  }
  const handleScroll = () => {
    if (window.scrollY > 20) {
      document.querySelector(".nav").className = "nav stickyNav";
    } else {
      document.querySelector(".nav").className = "nav";
    }
  };
  window.addEventListener('scroll', handleScroll);

  return (
    <div className='nav'>

      <div class='nav-contain'>
        <div>
          <img
            alt=''
            src='/images/brainlox_code_now_logo.png'
            height='60px'
            width='150px'
          />
        </div>
        <div className='navbar'>
          <div className='nav-options'>
            <div className='nav-options-sub'>

            <NavLink onClick={() => {
              amplitude.getInstance.logEvent("user clicked on practice set");
            }} style={act1 ? active : { textDecoration: 'none', color: 'white' }} to='/' className={({ isActive }) => {
              isActive ? setact1(true) : setact1(false)
            }}>
              <MenuItem>
                <strong className='nav-item'>Practice</strong>
              </MenuItem>
            </NavLink>

            <NavLink onClick={() => {
              amplitude.getInstance.logEvent("user clicked on playground");
            }} style={act2 ? active : { textDecoration: 'none', color: 'gray' }} className={({ isActive }) => {
              isActive ? setact2(true) : setact2(false)
            }} to='/playground'>
              <MenuItem>
                <strong className='nav-item'>Playground</strong>
              </MenuItem>
            </NavLink>

            <NavLink onClick={() => {
              amplitude.getInstance.logEvent("user clicked on workshop");
            }} style={act3 ? active : { textDecoration: 'none', color: 'gray' }} className={({ isActive }) => {
              isActive ? setact3(true) : setact3(false)
            }} to='/workshops'>
              <MenuItem>
                <strong className='nav-item'>Workshop</strong>
              </MenuItem>
            </NavLink>

            <NavLink onClick={() => {
              amplitude.getInstance.logEvent("user clicked on support");
            }} style={act3 ? active : { textDecoration: 'none', color: 'gray' }} className={({ isActive }) => {
              isActive ? setact3(true) : setact3(false)
            }} to='/contact'>
              <MenuItem>
                <strong className='nav-item'>Support</strong>
              </MenuItem>
            </NavLink>

            <MenuItem onClick={() => {
              amplitude.getInstance.logEvent("user clicked on feedback");
            }} style={{ textDecoration: 'none', color: 'gray' }}>
              <a target="_blank" rel='noreferrer' href='https://i.brainlox.com/code-now-feedback' style={{ textDecoration: 'none' }}>
                <strong className='nav-item'>Feedback</strong>
              </a>
            </MenuItem>
              </div>

            {(localStorage.getItem("Email") != null) &&
              <NavLink style={{ textDecoration: 'none' }} onClick={out} to='/'>
                <MenuItem>
                  <strong className='nav-item' onClick={() => {
                    amplitude.getInstance.logEvent("user clicked on log out button");
                  }}>
                    <LogoutIcon />
                    {localStorage.getItem("Email") === null ? "Log in" : "Log Out"}
                  </strong>
                </MenuItem>
              </NavLink>
            }

            {(localStorage.getItem("Email") == null) &&
              <NavLink style={{ textDecoration: 'none' }} to='/Signup'>
                <MenuItem>
                  <strong className='nav-item' onClick={() => {
                    amplitude.getInstance.logEvent("user clicked on log out button");
                  }}>
                    Log In
                  </strong>
                </MenuItem>
              </NavLink>
            }

          </div>
        </div>
      </div>
      <div className='hamburg'>
        <span onClick={handleClick} className='nav-menu-btn'>
          <h1><Hamburger toggled={open} /></h1>
        </span>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <NavLink onClick={() => {
            amplitude.getInstance.logEvent("user clicked on practice set");
          }} style={{ textDecoration: 'none' }} to='/'>
            <MenuItem>
              <strong className='nav-item'>Practice</strong>
            </MenuItem>
          </NavLink>

          <NavLink onClick={() => {
            amplitude.getInstance.logEvent("user clicked on playground");
          }} style={{ textDecoration: 'none' }} to='/playground'>
            <MenuItem>
              <strong className='nav-item'>Playground</strong>
            </MenuItem>
          </NavLink>

          <NavLink onClick={() => {
            amplitude.getInstance.logEvent("user clicked on workshop");
          }} style={{ textDecoration: 'none' }} to='/workshop'>
            <MenuItem>
              <strong className='nav-item'>Workshop</strong>
            </MenuItem>
          </NavLink>

          <MenuItem onClick={() => {
            amplitude.getInstance.logEvent("user clicked on feedback");
          }}>
            <a target="_blank" rel='noreferrer' href='https://i.brainlox.com/code-now-feedback'>
              <strong className='nav-item'>Feedback</strong>
            </a>
          </MenuItem>

          {(localStorage.getItem("Email") != null) &&
            <NavLink style={{ textDecoration: 'none' }} onClick={out} to='/'>
              <MenuItem>
                <strong className='nav-item' onClick={() => {
                  amplitude.getInstance.logEvent("user clicked on log out button");
                }}>
                  <LogoutIcon />
                  {localStorage.getItem("Email") === null ? "Log in" : "Log Out"}
                </strong>
              </MenuItem>
            </NavLink>
          }

          {(localStorage.getItem("Email") == null) &&
            <NavLink style={{ textDecoration: 'none' }} to='/Signup'>
              <MenuItem>
                <strong className='nav-item' onClick={() => {
                  amplitude.getInstance.logEvent("user clicked on log in button");
                }}>
                  Log In
                </strong>
              </MenuItem>
            </NavLink>
          }

        </Menu>
      </div>
    </div>
  )
}
export default Navbar;
