import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import "../css/difficulty.css";
import CourseCard from './CourseCard';
import axios from 'axios';
import Section from "./Section"
import amplitude from "amplitude-js"
import CookieConsent from 'react-cookie-consent';
import Leaderboard from './Leaderboard';
  
export default function Difficulty({ ptype }) {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  // const [ismember,Setismember]=useState(false);
  const [IsLoading, setIsLoading] = useState(true);
  // const email=localStorage.getItem("Email")

  const event_amp="course card clicked"

  useEffect(() => {
    if (ptype === "problems") {
      axios({
        method: "GET",
        withCredentials: true,
        url: "https://brainlox-python-api.herokuapp.com/python/problems"
      }).then((res) => {
        setRows(res.data);
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
      });
    }
    else {
      axios({
        method: "GET",
        withCredentials: true,
        url: "https://brainlox-python-api.herokuapp.com/python/workshops"
      }).then((res) => {
        setRows(res.data);
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
      });
    }

  }, [ptype])

  const bystatus=(a,b)=>{
    if(a.status<b.status){
      return 1;
    }else if(b.status<a.status){
      return -1;
    }else{
      return 0;
    }
  }
  rows.sort(bystatus);
  console.log(localStorage.getItem("ispromo"));
  
  // useEffect(()=>{
  //   axios({
  //     method: "GET",
  //     withCredentials: true,
  //     url: `https://brainlox-python-api.herokuapp.com/python/me?email=${email}` 
  //   }).then((res) => {
  //     Setismember(res.data); 
  //   }).catch((err) => {
  //     console.log(err);
  //   });
  // },[ptype,email])
  // let user = email.substring(0,localStorage.getItem("Email").indexOf('@'));
  return (
    <div class='big-container'>
      <center>
        {ptype==='problems'?<Section
          type="prob"
          num="100+"
          title="Practice 100+ Program sets for FREE💥"
        />:<Section
          type="work"
          title={"Want to start a project? A step-by-step approach on developing a project."}
        />}
      </center>
      <div className = "difficulty" >
        <div className="diff-heading">
          <div className='two-side'>
        </div>
        </div>
        {IsLoading && <div className="loader">
            <Spinner color="black" />
            </div>}
        <div className='course-cards'>
          {rows.map((row,_i) => (            
                <div key={_i} onClick = {()=>{
                  if (row.status === "Unlocked" ){
                    ptype === "problems" ?
                      navigate("/idePage", { state: { ideType: "problem", title: row.title } }) :
                      navigate("/idePage", { state: { ideType: "workshop", title: row.title } });
                  }
                  else if(localStorage.getItem("ispromo")==="true"){
                    ptype === "problems" ?
                      navigate("/idePage", { state: { ideType: "problem", title: row.title } }) :
                      navigate("/idePage", { state: { ideType: "workshop", title: row.title } });
                  }
                  else {
                    Swal.fire({
                      icon: 'question',
                      title: 'Please login with (promo code) to access this content',
                      showCancelButton: true,
                      confirmButtonText: 'Yes'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/Signup");
                        amplitude.getInstance.logEvent("login button clicked");
                      }else{
                        amplitude.getInstance.logEvent("cancel button clicked");
                      }
                    })
                  }
                  amplitude.getInstance.logEvent(event_amp);
                }}>
                  <CourseCard 
                  title={row.title}
                  difficulty={row.difficulty}
                  status={row.status}
                  questions={row.allQuestions.length}
                />
                </div>
            ))}
        </div>
      </div>
      <Leaderboard/>
</div>
  )
}
