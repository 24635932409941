import React from "react";
import "../css/learder.css";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Leaderboard = () => {
  return (
    <div>
      <div className="leaderboard">
        <div className="box-c">
          <div className="box-c-card">
            <h2>
              <div className="leader-text-sub">Top performer of Month</div>
            </h2>
            <div className="text-box card-pad l-section">
              <div className="crd-in">
                <p className="card-name">Users</p>
                <p className="card-name">Ratings</p>
              </div>
              <div className="perfomer">
                <div className="peformer">
                  <h6>
                    There are no records to display for the selected data range{" "}
                  </h6>
                  {/* <div className="performer plastic high">
                  <p className="performer1">1.<span className="span">7 <StarIcon/></span><span className="ne">gennady.korotkevich</span></p>
                  <p className="pt-rank">3402</p>
                </div>
                <div className="performer plastic ">
                <p className="performer1">2.<span className="span">7 <StarIcon/></span><span className="ne">maroonrk</span></p>
                  <p className="pt-rank">3299</p>
                </div>
                <div className="performer plastic ">
                  <p className="performer1">3.<span className="span">7 <StarIcon/></span><span className="ne">ksun48</span></p>
                  <p className="pt-rank">3243</p>
                  </div>
                  <div className="performer plastic">
                  <p className="performer1">4.<span className="span">7 <StarIcon/></span><span className="ne">petr</span></p>
                  <p className="pt-rank">3076</p>
                </div>
                <div className="performer plastic">
                  <p className="performer1">5.<span className="span">7 <StarIcon/></span><span className="ne">heno239</span></p>
                  <p className="pt-rank">3072</p>
                </div>
  <hr /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="box-c-card">
            <h2>
              <div className="leader-text-sub">Top performer of Month</div>
            </h2>
            <div className="text-box card-pad1 l-section">
              <div className="crd-in">
                <p className="card-name">Users</p>
                <p className="card-name">Contributions</p>
              </div>

              <div className="perfomer">
                <div className="peformer">
                  <h6>
                    There are no records to display for the selected data range{" "}
                  </h6>
                  {/*<div className="performer plastic high">
                  <p className="performer1">1.<span className="span1">6 <StarIcon/></span><span className="ne">iceknight1093</span></p>
                  <p className="pt-rank">89</p>
                </div>
                <div className="performer plastic ">
                  <p className="performer1">2.<span className="span2">4 <StarIcon/></span><span className="ne">heisenberg2000</span></p>
                  <p className="pt-rank">22</p>
                </div>
                <div className="performer plastic ">
                  <p className="performer1">3.<span className="span3">3 <StarIcon/></span><span className="ne">hrishik85</span></p>
                  <p className="pt-rank">16</p>
                </div>
                <div className="performer plastic">
                  <p className="performer1">4.<span className="span4">1 <StarIcon/></span><span className="ne">kiran8268</span></p>
                  <p className="pt-rank">9</p>
                </div>
                <div className="performer plastic">
                  <p className="performer1">5.<span className="span5">5 <StarIcon/></span><span className="ne">nitinkumar1238</span></p>
                  <p className="pt-rank">8</p>
                </div>
                <hr /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
